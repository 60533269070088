<template>
  <div class="pageV">
    <div
      class="header"
      style="position: fixed; top: 0; background-color: #00000000;"
    >
      <van-nav-bar
        :title="$t('inviteTitle')"
        left-arrow
        @click-left="$router.go(-1)"
        style="box-shadow: 0; background-color: #00000000;"
      />
    </div>
    <div class="rqBgV">
      <span class="qrTv1">{{ $t("inviteCode") }}</span>
      <span class="qrTv2">{{ inviteCode }}</span>
      <span class="qrTv3">{{ inviteUrl }}</span>
      <span
        class="qrTv4"
        @click.prevent="onCopyClick()"
        :data-clipboard-text="inviteUrl"
      >
        {{ $t("copy") }}
      </span>
      <van-divider
        :dashed="true"
        style="margin-top: 0.56rem; height: 1px; border-color: #E6E6E6;"
      />
      <vue-qr class="qrIv" :text="inviteUrl" ></vue-qr>

    </div>
  </div>
</template>
<script>
import Clipboard from 'clipboard'
import vueQr from 'vue-qr'
export default {
  components: { vueQr },
  data() {
    return {
      inviteCode: "",
      inviteUrl: "",
    };
  },
  created() {
    this.getinfo();
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get("/home/user/index");
      if (data) {
        if (data.code === 200) {
          this.inviteCode = data.data.userinfo.invite_code;
          var url = window.location.protocol;
          var domain3 = window.location.host;
          var inUrl =
            url +
            "//" +
            domain3 +
            "/index.html?invite=" +
            data.data.userinfo.invite_code +
            "#/register";
          this.inviteUrl = inUrl;
          
        }
      }
    },
    onCopyClick() {
      const clipboard = new Clipboard(".qrTv4");
      clipboard.on("success", (e) => {
        this.$toast.success(this.$t("common.success"));
      });
    },
  },
};
</script>
<style lang="less" scoped>
.pageV {
  min-width: 100vw;
  min-height: 100vh;
  background-image: url("../../assets/img/invite_bg.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rqBgV {
  width: 263px;
  height: 333px;
  background-image: url("../../assets/img/invite_qr_bg.svg");
  display: flex;
  flex-direction: column;
  align-items: center;

  .qrTv1 {
    color: #000;
    font-size: 1.12rem;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    margin-top: 0.56rem;
  }

  .qrTv2 {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    color: #0a51a1;
    font-size: 1.5rem;
    margin-top: 0.12rem;
  }

  .qrTv3 {
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 0.68rem;
    color: #666;
    margin-top: 0.18rem;
    margin-left: 30px;
    margin-right: 30px;
  }

  .qrTv4 {
    min-height: 27px;
    background: #f63c72;
    border-radius: 4.25rem;
    margin-top: 0.6rem;
    width: fit-content;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 600;
    color: #fff;
    font-size: 0.68rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .qrIv {
    width: 137px;
    height: 137px;
    margin-top: 1.18rem;
  }
}
</style>
