import { render, staticRenderFns } from "./SgrInvite.vue?vue&type=template&id=40536432&scoped=true&"
import script from "./SgrInvite.vue?vue&type=script&lang=js&"
export * from "./SgrInvite.vue?vue&type=script&lang=js&"
import style0 from "./SgrInvite.vue?vue&type=style&index=0&id=40536432&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40536432",
  null
  
)

export default component.exports